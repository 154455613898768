import './App.css';
import { Link } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <div className="App-header2">
        Lacvimed
      </div>
      <div className="App-header">
        Work In Progress
      </div>
    </div>
  );
}

export default App;
